<template>
  <el-menu
    :default-active="activeUrl"
    class="el-menu-demo"
    mode="horizontal"
    @select="tabClick"
  >
    <el-menu-item
      v-for="(item, index) in hasRouter"
      :key="index"
      :index="item.path"
      v-show="item.show"
      >{{ item.label }}</el-menu-item
    >
  </el-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    activeUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      chilMenus: (state) => state.app.chilMenus,
    }),
  },
  data() {
    return {
      hasRouter: [
        {
          label: "系统管理",
          path: "/setting/system",
          permissions: "setting-system",
          show: false,
        },
      /*  {
          label: "应用管理",
          path: "/setting/application",
          permissions: "setting-application",
          show: false,
        },*/
        {
          label: "电子设备",
          path: "/setting/application",
          permissions: "setting-application",
          show: false,
        },
      ],
    };
  },
  mounted() {
    let permissions = this.$store.state.app.permissions;
    this.hasRouter.forEach((route) => {
      permissions.forEach((item) => {
        if (route.permissions === item) {
          route.show = true;
        }
      });
    });
  },
  methods: {
    tabClick(url) {
      const menus = this.$store.getters.menus;
      const path = this.getPath(menus, url).replace('/index', '')
      if(url==='/setting/application'){
        this.$router.push(url);
      }else{
        this.$router.push(path);
      }
    },
    getPath(data, url,path) {
      for(let i=0;i<data.length;i++){
        if (data[i].path === url) {
          path =  this.getPath2([data[i]])
        } else {
          if(!path){
            path=this.getPath(data[i].children, url,path);
          }
        }
      }
      return path
    },
    getPath2(data){
      if(data[0].children?.length){
        return this.getPath2(data[0].children)
      }else{
        return data[0].path
      }
    },
  },
};
</script>

<style>
.el-menu {
  background: rgba(12, 39, 92, 0.9);
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 3px solid #3b98ff;
  color: #a3d6ff;
  opacity: 1;
}
.el-menu--horizontal > .el-menu-item {
  color: #a3d6ff;
  opacity: 0.36;
  margin-left: 20px;
  height: 48px;
  line-height: 48px;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #a3d6ff;
}
</style>
