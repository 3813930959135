<template>
  <div class="jump" :class="{'jump-bottom':jumpBt}">
    <div class="jump-list">
      <p
          v-for="(item, index) in jumpList"
          :key="index"
          @click="toUrl(item.url)"
      >
        {{ item.name }}
      </p>
    </div>
    <div class="jump-tools">
      <img @click="back()" src="@/assets/btn_back.png" alt="">
      <img @click="refresh()" src="@/assets/btn_refresh.png" alt="">
    </div>
  </div>
</template>

<script>
import { getCookie, removeCookie } from '@/utils/auth'
export default {
  props: {
    jumpList: {
        type: Array,
        default: () => {
            return [];
        },
    },
    jumpBt: {
        type: Boolean,
        default: false
    }
  },
  methods:{
    toUrl(url=null){
      if(url){
        this.$router.push(url)
      }
    },
    back(){
      const history = getCookie('history')
      const isHistory = window.history.length
      if(isHistory === 1){
        this.$router.push(history)
        removeCookie('history')
      }else{
        this.$router.go(-1)
      }
    },
    refresh(){
      this.$router.go(0)
    }
  },

};
</script>

<style lang="scss" scoped>
.jump {
  display: flex;
  padding: 20px 0px 14px 25px;
  align-items: center;
  justify-content: space-between;
  .jump-list{
    display: flex;
  }
  .jump-tools{
    display: flex;
    img{
      cursor: pointer;
      margin-right: 20px;
    }
  }
  p {
    color: rgba(163, 214, 255, 0.2);
    font-size: 14px;
    cursor: pointer;
  }
  p:after {
    content: "/";
    color: rgba(163, 214, 255, 0.2);
    margin: 0 10px;
  }
  p:last-child {
    color: #a3d6ff;
    //color: rgba(163, 214, 255, 0.2);
    cursor: text;
  }
  p:last-child:after {
    content: "";
  }
}
.jump-bottom{
    border-bottom: 1px solid rgba(163, 214, 255, 0.2);
}
</style>
